<style scoped lang="scss">
  .comp-drag-imageupload-box {
    /deep/ .el-upload-dragger {
      height: auto;
    }
    /deep/ .el-upload__text {
      margin-bottom: 30px;
    }
    /deep/ .el-upload__tip {
      margin: 0;
      color: #ff0000;
    }
    /deep/ .el-image__error {
      min-height: 180px;
    }
    .images {
      margin: 0 10px 10px 0;
      float: left;
      max-width: 100%;
      height: auto;
      border-radius: 4px;
      position: relative;
      background-color: #f4f4f4;
      .image {
        display: block;
        background-color: #ffffff;
        border: 1px dotted #f0f9ff;
      }
      .btns {
        display: flex;
        justify-content: flex-end;
        height: 22px;
        padding: 0 2px;
        border: 1px dotted #999;
        border-top: 0;
        align-items: center;
      }
      .btn {
        margin-left: 20px;
        display: inline-block;
        width: 16px;
        height: 16px;
        line-height: 16px;
        text-align: center;
        background: #fefefe;
        border: 1px solid #ddd;
        cursor: pointer;
      }
      .btn.edit {
        left: 2px;
        color: #1f7dff;
      }
      .btn.prev {
        left: 28px;
      }
      .btn.next {
        right: 28px;
      }
      .btn.del {
        right: 2px;
        color: #ff0000;
      }
    }
    .image {
      width: 100%;
      height: auto;
      display: block;
    }
  }
  .size-36 {
    /deep/ .el-upload-dragger {
      width: 36px;
      height: 36px;
      .el-icon-plus {
        font-size: 16px;
        margin: 0;
        line-height: 36px;
      }
      .el-image__error {
        min-height: 36px;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
</style>

<template>
  <div class="comp-drag-imageupload-box" :class="size">
    <div class="images" v-if="imgUrl">
      <el-image class="image" :src="imgUrl" :preview-src-list="[imgUrl]" :z-index="9999999990"></el-image>
      <div class="btns">
        <el-upload class="btn edit" :show-file-list="false" title="修改"
          accept="image/jpeg,image/gif,image/png"
          :headers="uploadHeaders"
          :action="uploadUrl" 
          :data="uploadData"
          :on-success="handleAvatarSuccess"
          :on-error="handleAvatarError"
          :before-upload="beforeAvatarUpload">
          <template>
            <i class="el-icon-edit"></i>
          </template>
        </el-upload>
        <a class="btn del" @click="deleteUrl()" title="删除"><i class="el-icon-delete"></i></a>
      </div>
    </div>
    <template v-else>
      <el-upload class="" drag :show-file-list="false"
        accept="image/jpeg,image/gif,image/png"
        :headers="this.uploadHeaders"
        :action="uploadUrl" 
        :data="uploadData"
        :on-success="handleAvatarSuccess"
        :on-error="handleAvatarError"
        :before-upload="beforeAvatarUpload">
          <i class="el-icon-upload"></i>
          <template v-if="!size">
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">只能上传jpg/png/gif文件，且不超过10MB</div>
          </template>
      </el-upload>
    </template>
  </div>
</template>

<script>
export default {
  name: "compImageDragUpload",
  props: {
    folder: String,
    fileName: String,
    imageUrl: String,
    size: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      uploadUrl: "",
      uploadData: {
        Folder: this.folder || "",
        FileName: this.fileName || ""
      },
      imgUrl: ""
    }
  },
  watch: {
    imageUrl(val) {
      this.imgUrl = val ? (window.$util.isUrl(val) ? val : (this.uploadBaseUrl + val)) : ""
    }
  },
  mounted() {
    // 数据初始化
    this.uploadUrl = this.uploadBaseUrl + "/api/services/app/Shared/UploadFile"
    this.imgUrl = this.imageUrl ? (window.$util.isUrl(this.imageUrl) ? this.imageUrl : (this.uploadBaseUrl + this.imageUrl)) : ""
  },
  methods: {
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
          this.errorMsg('上传图片大小不能超过 10MB!')
          return false
      }
      window.$common.fullLoading()
      return true
    },
    handleAvatarSuccess(res, file) {
      window.$common.closeFullLoading()
      this.imgUrl = this.uploadBaseUrl + res.result.fileUrl
      this.emitImageUrl(res.result)
    },
    handleAvatarError() {
      window.$common.closeFullLoading()
    },
    deleteUrl() {
      // 删除
      this.imgUrl = ""
      this.emitImageUrl();
    },
    emitImageUrl(imgUrl) {
      this.$emit("emitImageUrl", imgUrl || "")
    }
  }
}
</script>
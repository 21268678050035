import request from '@/utils/request'

// 获取置顶位置的广告
export function QueryPositionAdInfo(data) {
  return request({
    url: '/api/services/app/Ad/QueryPositionAdInfo',
    method: 'post',
    data
  })
}

// 创建
export function AdCreate(data) {
  return request({
    url: '/api/services/app/Ad/Create',
    method: 'post',
    data
  })
}

// 更新
export function AdUpdate(data) {
  return request({
    url: '/api/services/app/Ad/Update',
    method: 'put',
    data
  })
}

// 代理列表
export function AdList(data) {
    return request({
      url: '/api/services/app/Ad/GetPagedList',
      method: 'get',
      params: data
    })
}

// 详情
export function AdDetail(data) {
  return request({
    url: '/api/services/app/Ad/Get',
    method: 'get',
    params: data
  })
}

export function remove(data) {
  return request({
    url: '/api/services/app/Ad/Delete',
    method: 'delete',
    params: data
  })
}

export function removeMuliple(data) {
  return request({
    url: '/api/services/app/Ad/Deletes',
    method: 'delete',
    params: data
  })
}
<style lang="scss" scoped>
.page-advert-list {
    .list-image{
        display: block;
        max-width: 180px;
        max-height: 80px;
        img {
            width: 100%;
            height: 100%;
        }
    }
}
</style>

<template>
    <div class="page-advert-list">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <div class="fl">
                    <el-button class="mrgb5" type="primary" icon="el-icon-plus" @click="_edit()" size="medium">添加广告</el-button>
                </div>
                <div class="fr">
                    <el-select class="mrgr5 mrgb5" v-model="filter.type" placeholder="类型" clearable @change="_search()" size="medium">
                        <el-option v-for="item in levelOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                    <el-select class="mrgr5 mrgb5" v-model="filter.position" placeholder="位置" clearable @change="_search()" size="medium">
                        <el-option v-for="item in locationOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                    <el-input class="c-el-input mrgr5 mrgb5" placeholder="搜索备注" clearable v-model="filter.keywords"
                        size="medium"></el-input>
                    <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
                </div>
                <div class="clearb"></div>
            </div>
        </div>
        <div>
            <el-table :data="tableData" border fit highlight-current-row row-key="id">
                <el-table-column prop="id" label="ID" width="60"></el-table-column>
                <el-table-column prop="typeDesc" label="类型"></el-table-column>
                <el-table-column prop="positionDesc" label="位置"></el-table-column>
                <el-table-column prop="merchantId" label="商家ID">
                    <template slot-scope="scope">
                        <div>
                            {{ scope.row.merchant ? scope.row.merchant.name + `【${scope.row.merchantId}】` : '平台广告' }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="imageUrl" label="图片">
                    <template slot-scope="scope">
                        <a class="list-image" target="_blank" :href="scope.row.imageUrl">
                            <img :src="scope.row.imageUrl" />
                        </a>
                    </template>
                </el-table-column>
                <el-table-column prop="isActive" label="启用状态">
                    <template slot-scope="scope">
                        <div class="store-info">
                            <div class="store-name">
                                <el-tag type="danger" size="mini" v-if="!scope.row.isActive">禁用</el-tag>
                                <el-tag type="success" size="mini" v-if="scope.row.isActive">启用</el-tag>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="beginDate" label="投放时间" width="150">
                    <template slot-scope="scope">
                        <div>{{ scope.row.beginDate ? '开始：' + scope.row.beginDate.split(' ')[0] : '永久' }}</div>
                        <div>{{ scope.row.endDate ? '结束：' + scope.row.endDate.split(' ')[0] : '永久' }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="beginTime" label="生效时段">
                    <template slot-scope="scope">
                        <div>{{scope.row.beginTime?'开始：'+scope.row.beginTime:'全天'}}</div>
                        <div>{{scope.row.endTime?'结束：'+scope.row.endTime:'全天'}}</div>
                    </template>
                </el-table-column>  
                <el-table-column prop="creationTime" label="创建时间">
                    <template slot-scope="scope">
                        <div>{{scope.row.creationTime.split(' ')[0]}}</div>
                        <div>{{scope.row.creationTime.split(' ')[1]}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="maxDistance" label="有效半径(km)">
                    <template slot-scope="scope">
                        <div>
                            {{scope.row.maxDistance?scope.row.maxDistance:'全国覆盖'}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注"></el-table-column>
                <el-table-column label="操作" width="100px" fixed="right">
                    <template slot-scope="scope">
                        <el-dropdown trigger="click">
                        <el-button type="primary" plain size="mini">更多
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item @click.native="_edit(scope.row)">修改/详情</el-dropdown-item>
                            <el-dropdown-item @click.native="_delete(scope.row)">删除</el-dropdown-item>
                        </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-container" v-if="isPagination">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="filter.page" :page-sizes="appPageSizes" :page-size="filter.size"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
        <!--编辑-->
        <edit :ref="refEdit" @refreshData="_search()"></edit>
    </div>
</template>

<script>
import enumConfigs from "@/mixins/enumConfigs"
import * as funAdvert from "@/api/advert/index"
import edit from "./components/edit"
export default {
    name: "pageAdvertList",
    mixins: [enumConfigs],
    components: { edit },
    data() {
        return {
            refEdit: "refAdvertListToEdit",
            tableItems: {},
            tableData: [],
            total: null,
            isPagination: false,
            filter: {
                page: 1,
                size: window.$common.appPageSize,
                type: null,
                position: null,
                Keywords:''
            },
            categories: []
        }
    },
    created() {
        this.getDataList()
    },
    computed: {
        levelOptions() {
            return this.arrAdType
        },
        locationOptions() {
            return this.arrAdPosition
        }
    },
    methods: {
        handleSizeChange(val) {
            this.filter.size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            this.filter.page = val
            this.getDataList()
        },
        async getDataList() {
            window.$common.fullLoading()
            funAdvert.AdList(this.filter).then(response => {
                window.$common.closeFullLoading()
                this.tableItems = response
                this.total = response.totalCount
                this.isPagination = response.totalCount > 0 || false
                if (response && response.items && response.items.length > 0) {
                    this.tableData = response.items
                } else {
                    this.tableData = []
                }
            })
        },
        _edit(row) {
            this.$refs[this.refEdit].show(row)
        },
        _delete(row) {
            this.confirm("确认要删除该广告吗?").then(() => {
                window.$common.fullLoading()
                funAdvert.remove({ id: row.id }).then(res => {
                    window.$common.closeFullLoading()
                    this.successMsg("删除成功")
                    this.getDataList()
                })
            })
        },
        _search() {
            this.filter.page = 1
            this.getDataList()
        }
    }
}
</script>
<style lang="scss" scoped>
/deep/ .comp-advert-edit {
    margin-top: 6vh !important;
    max-width: 800px;
}
</style>

<template>
    <div>
        <el-dialog :title="titlePrev + '广告'" custom-class="c-el-dialog comp-advert-edit" :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
            <el-form :model="dialogData" :rules="formRules" :ref="formRefName" label-width="130px" size="small">
                <el-row :gutter="10">
                    <el-col :xs="24">
                        <el-form-item label="类型" prop="type">
                            <el-select v-model="dialogData.type" placeholder="请选择类型">
                                <el-option v-for="item in levelOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="显示位置" prop="position">
                            <el-select v-model="dialogData.position" placeholder="请选择显示位置">
                                <el-option v-for="item in locationOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" v-if="isMerchant">
                        <el-form-item label="商家ID" prop="merchantId">
                            <el-select v-model="dialogData.merchantId" filterable remote reserve-keyword placeholder="商家(输入搜索)"
                                :remote-method="renderMerchantOptions" :loading="merchantSearchLoading" clearable>
                                <el-option v-for="item in merchantItems" :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="图片" prop="imageUrl">
                            <image-upload :imageUrl="dialogData.imageUrl" :folder="'advert'" @emitImageUrl="setPhoto"></image-upload>
                            <div class="f12 colff0" v-if="dialogData.position==0">建议图片大小【750*150】</div>
                            <div class="f12 colff0" v-if="dialogData.position==1">建议图片大小【750*1000】</div>
                            <div class="f12 colff0" v-if="dialogData.position==2">建议图片大小【750*150】</div>
                            <div class="f12 colff0" v-if="dialogData.position==3">建议图片大小【750*1500】</div>
                            <div class="f12 colff0" v-if="dialogData.position==4">建议图片大小【750*300】</div>
                            <div class="f12 colff0" v-if="dialogData.position==5">建议图片大小【750*300】</div>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="图片链接/客服电话" prop="linkUrl">
                            <el-input v-model="dialogData.linkUrl" placeholder="请输入图片链接(点击图片跳转的链接)/或者客服电话"></el-input>
                            <div>例：{{ uploadBaseUrl }}/Article/Detail?id=2 【id为文章id】</div>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="投放日期" prop="beginDate">
                            <el-switch class="mrgr20" v-model="isCustom"  active-text="自定义" @change="customChange" inactive-text="永久"></el-switch>
                            <template v-if="isCustom">
                                <el-date-picker v-model="Dates" type="daterange" value-format="yyyy/MM/dd" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期">
                                </el-date-picker>
                            </template>
                        </el-form-item>
                        <div class="clearb"></div>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="生效时段" prop="beginTime">
                            <el-switch class="mrgr20" v-model="isCustom1" active-text="自定义" @change="customChange1" inactive-text="全天"></el-switch>
                            <template v-if="isCustom1">
                                <el-time-picker is-range v-model="Times" value-format='HH:mm' range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"
                                    @change="TimeChange" placeholder="选择时间范围">
                                </el-time-picker>
                            </template>
                        </el-form-item>
                        <div class="clearb"></div>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="有效半径" prop="maxDistance" v-if="isMerchant">
                            <el-input v-model="dialogData.maxDistance" placeholder="最大覆盖半径">
                                <template slot="append">km</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="启用状态">
                            <el-switch v-model="dialogData.isActive" active-text="启用" inactive-text="禁用"></el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="备注">
                            <el-input v-model="dialogData.remark" placeholder="备注(备注代理等等，可在搜索框搜索)"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
                <el-button size="small" @click="_close()">取消</el-button>
                <el-button size="small" type="primary" @click="_save()">提交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import configMixins from "@/mixins/config"
import enumConfigs from "@/mixins/enumConfigs"
import * as funMerchant from "@/api/merchant/index"
import * as funAdvert from "@/api/advert/index"
import ImageUpload from "@/components/ImageDragUpload"
export default {
    name: "compArticleEdit",
    mixins: [configMixins, enumConfigs],
    components: { ImageUpload },
    data() {
        return {
            showDialog: false,
            titlePrev: "",
            dialogType: "",
            defaultData: {
                id: 0,
                type:null,
                position:null,
                merchantId:null,
                imageUrl:'',
                linkUrl:'',
                beginDate:null,
                endDate:null,
                beginTime:null,
                endTime:null,
                isActive:true,
                remark:'',
                maxDistance:''
            },
            dialogData: {},
            formRefName: "refAdvertEditForm",
            formRules: {
                type: [{ required: true, message: "请选择类型", trigger: ["blur", "change"] }],
                position: [{ required: true, message: "请选择位置", trigger: ["blur", "change"] }],
                imageUrl: [{ required: true, message: "请上传图片", trigger: ["blur", "change"] }],
                // beginDate: [{ required: true, message: "请选择投放日期", trigger: ["blur", "change"] }],
                // beginTime: [{ required: true, message: "请选择生效时间段", trigger: ["blur", "change"] }],
                merchantId:[{ required: true, message: "请选择商家ID", trigger: ["blur", "change"] }],
                maxDistance:[{ required: true, message: "请输入最大距离", trigger: "blur" }]
            },
            merchantItems: [],
            isMerchant: false,
            Dates: [],
            Times: ['00:00:00',"23:59:59"],
            isCustom: false,
            isCustom1: false,
            merchantInfo: ""
        }
    },
    watch: {
        "dialogData.type"(value) {
            if(value == 2){
                this.isMerchant = true
            }else{
                this.isMerchant = false
                this.dialogData.merchantId = null
                this.dialogData.maxDistance = ''
            }
        },
        Dates(value) {
            if (value) {
                this.dialogData.beginDate = value[0]
                this.dialogData.endDate = value[1]
            } else {
                this.dialogData.beginDate = null
                this.dialogData.endDate = null
            }
        }
    },
    computed: {
        levelOptions() {
            return this.arrAdType
        },
        locationOptions() {
            return this.arrAdPosition
        }
    },
    methods: {
        show(row) {
            this.dialogData = JSON.parse(JSON.stringify(this.defaultData))
            this.dialogType = row ? this.createOrUpdate[1] : this.createOrUpdate[0]
            if (this.dialogType == this.createOrUpdate[0]) {
                // 新增
                this.titlePrev = "新增"
                this.showDialog = true
            } else {
                // 修改
                this.titlePrev = "修改"
                this.dialogData.id = row.id
                window.$common.fullLoading()
                funAdvert.AdDetail({ id: this.dialogData.id }).then(res => {
                    window.$common.closeFullLoading()
                    for (let i in this.dialogData) {
                        this.dialogData[i] = res[i]
                    }
                    // 设置默认值
                    if(res.merchant) {
                        this.merchantInfo = res.merchant.name + `【${res.merchantId}】`
                    } else {
                        this.merchantInfo = null
                    }
                    if(res.beginDate) {
                        this.isCustom=true
                        this.Dates = [this.dialogData.beginDate ,this.dialogData.endDate]//
                    } else {
                        this.isCustom = false
                        this.Dates = []
                    }
                    if(res.beginTime) {
                        this.isCustom1 = true
                        this.Times = [this.dialogData.beginTime + ':00', this.dialogData.endTime + ':00' ]
                    }else{
                        this.isCustom1 = false
                    }

                    this.showDialog = true
                })
            }
        },
        setPhoto(item) {
            this.dialogData.imageUrl = item.fileUrl || ""
        },
        //  点击时间段 选择
        TimeChange(e) {
            if (e) { 
                this.dialogData.beginTime = e[0]
                this.dialogData.endTime = e[1]
            } else {
                this.dialogData.beginTime = null
                this.dialogData.endTime = null
            }
        },
        //  点击自定义时间
        customChange(value) {  //自定义时间
            this.dialogData.beginDate = null
            this.dialogData.endDate = null
        },
        customChange1(value) {//自定义u时间段
            if(value) {
                this.dialogData.beginTime = '00:00:00'
                this.dialogData.endTime = "23:59:59"
                this.Times = [this.dialogData.beginTime, this.dialogData.endTime]
            } else {
                this.dialogData.beginTime = null
                this.dialogData.endTime = null
            }
        },
        async renderMerchantOptions(query) {
            await funMerchant.GetAllMerchants({ keywords: query }).then(res => {
                this.merchantItems = res
            })
        },
        _save() {
            this.$refs[this.formRefName].validate((valid) => {
                if (valid) {
                    let commitData = JSON.parse(JSON.stringify(this.dialogData))
                    if (this.isCustom && (!commitData.beginDate || !commitData.endDate)) {
                        this.warningMsg("请选择投放日期")
                        return false
                    }
                    window.$common.fullLoading()
                    if (this.dialogType == this.createOrUpdate[0]){
                        // 创建
                        funAdvert.AdCreate(commitData).then(() => {
                            this.commonSuccess()
                        })
                    } else {
                        // 更新
                        funAdvert.AdUpdate(commitData).then(() => {
                            this.commonSuccess()
                        })
                    }
                } else {
                    console.log('error submit!')
                    return false
                }
            })
        },
        _close() {
            this.showDialog = false
            this.customChange()
            this.customChange1()
            this.Dates = []
            this.Times = ['00:00:00',"23:59:59"]
            this.merchantInfo = ""
            this.isMerchant = false
            this.isCustom = false
            this.isCustom1 = false
            this.$nextTick(() => {
                if (this.$refs[this.formRefName]) {
                    this.$refs[this.formRefName].resetFields()
                }
            })
        },
        commonSuccess(val) {
            let tips = val || "操作成功!"
            this.successMsg(tips, {
                onClose: () => {
                    this._close()
                    window.$common.closeFullLoading()
                    this.refreshData()
                }
            })
        },
        refreshData() {
            this.$emit("refreshData", true)
        }
    }
}
</script>